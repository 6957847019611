import React from "react"
import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"

export default class BurgerMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = { toggled: false }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    const currentState = this.state.toggled
    this.setState({ toggled: !currentState })
    this.props.handler()
  }

  render() {
    return (
      <Div>
        <Global
          styles={css`
            .hamburger {
              padding: 15px 15px;
              display: inline-block;
              cursor: pointer;
              transition-property: opacity, filter;
              transition-duration: 0.15s;
              transition-timing-function: linear;
              font: inherit;
              color: inherit;
              text-transform: none;
              background-color: transparent;
              border: 0;
              margin: 0;
              overflow: visible;

              position: absolute;
              top: 15px;
              right: 0;
            }
            .hamburger:hover {
              opacity: 0.7;
            }
            .hamburger.is-active:hover {
              opacity: 0.7;
            }
            .hamburger.is-active .hamburger-inner,
            .hamburger.is-active .hamburger-inner::before,
            .hamburger.is-active .hamburger-inner::after {
              background-color: #000;
            }
            .hamburger-box {
              width: 40px;
              height: 24px;
              display: inline-block;
              position: relative;
            }

            .hamburger-inner {
              display: block;
              top: 50%;
              margin-top: -2px;
            }
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
              width: 40px;
              height: 2px;
              background-color: #000;
              border-radius: 4px;
              position: absolute;
              transition-property: transform;
              transition-duration: 0.15s;
              transition-timing-function: ease;
            }
            .hamburger-inner::before,
            .hamburger-inner::after {
              content: "";
              display: block;
            }
            .hamburger-inner::before {
              top: -10px;
            }
            .hamburger-inner::after {
              bottom: -10px;
            }
            .hamburger--collapse .hamburger-inner {
              top: auto;
              bottom: 0;
              transition-duration: 0.13s;
              transition-delay: 0.13s;
              transition-timing-function: cubic-bezier(
                0.55,
                0.055,
                0.675,
                0.19
              );
            }
            .hamburger--collapse .hamburger-inner::after {
              top: -20px;
              transition: top 0.2s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                opacity 0.1s linear;
            }
            .hamburger--collapse .hamburger-inner::before {
              transition: top 0.12s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            .hamburger--collapse.is-active .hamburger-inner {
              transform: translate3d(0, -10px, 0) rotate(-45deg);
              transition-delay: 0.22s;
              transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            .hamburger--collapse.is-active .hamburger-inner::after {
              top: 0;
              opacity: 0;
              transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }
            .hamburger--collapse.is-active .hamburger-inner::before {
              top: 0;
              transform: rotate(-90deg);
              transition: top 0.1s 0.16s
                  cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            @media (min-width: 1100px) {
              .hamburger {
                display: none;
              }
            }
          `}
        />
        <button
          type="button"
          onClick={this.toggleNav}
          aria-label="Ouvrir menu de navigation"
          className={
            this.state.toggled
              ? "hamburger  hamburger--collapse is-active"
              : "hamburger  hamburger--collapse"
          }
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </Div>
    )
  }
}

const Div = styled.div``
